  import React from 'react';

  import styles from './ProvidentCloud.module.css';

  import ProvidentCloudHeader from './ProvidentCloudHeader/ProvidentCloudHeader';

  const ProvidentCloud = () => {
    return (
      <div className={styles.root}>
        <ProvidentCloudHeader />

      </div>
    );
  }

  export default ProvidentCloud;
