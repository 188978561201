import React from 'react';

import styles from './ProvidentCloudHeader.module.scss';

const ProvidentCloudHeader = () => {
  return (
    <div className={styles.root}>
      <img className={styles.logo}
           alt="ProvidentCloud Logo"
           src="assets/logo/logo.svg"/>

      <h1>ProvidentCloud</h1>
    </div>
  );
};

export default ProvidentCloudHeader;
